import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import AppState, { Authorizations, AuthState, UserFingerprint } from '@state/app.state';
import { Store, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import UserLoginInterface from '@interfaces/UserLoginInterface';

interface authResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  doLogin(user: UserLoginInterface) {
    return this.http.post<authResponse>(environment.urlAuth, JSON.stringify(user));
  }

  doLoginaAs(email: string) {
    return this.http.post<authResponse>(environment.urlAuth, JSON.stringify({ loginAs: email }));
  }

  isAuthenticated(): Observable<boolean> {
    const selector = createSelector(
      (state: AppState) => state.auth,
      (auth: AuthState) => !!auth.token
    );
    return this.store.select(selector);
  }

  allow(permission): Observable<boolean> {
    const selector = createSelector(
      (state: AppState) => state.auth,
      (auth: AuthState) => !!auth.authorizations[permission],
    );

    return this.store.select(selector);
  }

  auths(): Observable<Authorizations> {
    const selector = createSelector(
      (state: AppState) => state.auth,
      (auth: AuthState) => auth.authorizations,
    );

    return this.store.select(selector);
  }

  changePassword(data) {
    return this.http.post(environment.urlChangeLostPassword, data);
  }

  doLogout() {}

  getSettings(fingerPrint: string): Observable<any> {
    return this.http.get(environment.urlSettings, {
      headers: {
        'X-Finger': fingerPrint
      }
    });
  }
}
